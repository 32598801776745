@import './styles/colors.scss';

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
    border-radius: 0px !important;
}

:global {
    .Toastify__toast--info {
        background-color: $rpm-detroit-blue-tint2 !important;
        color: #000 !important;
    }

    .Toastify__toast--error {
        background-color: $wrong-red !important;
    }

    .Toastify__progress-bar--info {
        background: rgba(34, 36, 38, .45) !important;
    }

    .Toastify__toast--info button {
        color: #000;
    }
}