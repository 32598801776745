@import '../../styles/colors.scss';
.TableActions {
    display: flex;
    justify-content: flex-end;

    .PageSizeSelect {
        min-width: 5em !important;
        margin-left: 10px;
        padding: .68571429em 2.1em .58571429em 1em !important;
        line-height: 1.1em !important;
    }
}