@import '../../styles/colors.scss';

.tableLoaderCell {
    position: relative;
    background: white;
}

.footerRow {
    border-radius: 0 !important;
    padding: 0 !important;
    background: white;
    border-top: 1px solid rgba(34, 36, 38, 0.5) !important;

    .footerActions {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        background: transparent;
    }
}

.Container {
    position: relative;
    flex: 1 1;

    .TableHeaderActions {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background: white;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        position: sticky;
        top: 0;
        z-index: 10;

        .TableHeader {
            display: flex;

            .headerText {
                margin-bottom: 0;
            }

            .refreshBtn {
                cursor: pointer;
                padding-left: 15px;
            }
        }

        .TableActionsButton {
            background: $rpm-detroit-blue-tint1 !important;
            color: white;
        }
    }

    .Table {
        border-radius: 0 !important;
        margin: 0 !important;
        border: none !important;
        padding-bottom: 0;

        thead tr.FilterRow th {
            position: sticky !important;
            top: 0px;
            z-index: 10;
            padding: 0;
            padding-right: 25px;

            .SearchInput {
                width: 220px;
                float: right;

                input {
                    padding: .5em 1em;
                    padding-left: 5px;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid rgba(34, 36, 38, 0.41);
                    background: transparent;
                }

                ::placeholder {
                    color: rgba(34, 36, 38, 0.7);

                }
            }
        }

        thead tr.HeaderRow th {
            position: sticky !important;
            top: 32px;
            z-index: 10;
            color: rgba(0, 0, 0, .77);
            cursor: pointer;
            border-left: none !important;
            border-right: none !important;
        }

        thead tr.FilterRowWithoutActions th {
            position: sticky !important;
            top: 0px;
            z-index: 10;
        }

        thead tr.HeaderRowWithoutActions th {
            position: sticky !important;
            top: 0px;
            z-index: 10;
            color: rgba(0, 0, 0, .77);
            cursor: pointer;
            border-left: none !important;
            border-right: none !important;
        }

        tfoot tr th {
            position: sticky !important;
            bottom: 0;
            z-index: 10;
        }

        p.noData {
            text-align: center;
            font-size: 2rem;
            opacity: 0.3;
        }

        :global {
            .content.active {
                display: table-row !important;
            }
        }

        .spaceTableRow {
            border: none !important;

            .spaceBetween {
                background: $rpm-gunmetal-tint3 !important;
                border: none !important;
                padding: 5px;
            }
        }

        .expandedTitleRow {
            .cellContent {
                border-bottom: none !important;
            }
        }

        .expandedContentRow {

            .cellContent {
                border-top: none !important;
            }
        }

        .expandedContentRow,
        .expandedTitleRow,
        .cellContent,
        .tableRow {
            background: white !important;
        }

        .Accordion {
            width: 100%;
        }

        .cell {
            width: intrinsic;
            width: -moz-max-content;
            width: -webkit-max-content;
            user-select: text;
            -webkit-user-select: text;
            -khtml-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            cursor: text;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.Dimmer {
    position: relative !important;
    z-index: 1 !important;
    background-color: rgba(255, 255, 255, 0.45) !important;
    min-height: 50px;
}