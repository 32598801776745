@import '../../styles/colors.scss';

.ShipmentBoard {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .noData {
    text-align: center;
    font-size: 2rem;
    opacity: 0.3;
  }

  .ShipmentBoardHeader {
    padding: 15px 30px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    background: $rpm-dark-blue-tint3;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 11;

    .headerText {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  .ShipmentsContainer {
    width: 100%;
    padding: 15px 50px 70px 50px;
    flex: 1;
    position: relative;
    z-index: 1;

    .ShipmentItem {
      display: flex;
      flex-wrap: wrap;
    }

    .RouteItem {
      display: flex;
      flex-direction: column;

      .routeItemContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .pagingControlContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.13);
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 10;
    background: $rpm-dark-blue-tint3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 30px;
    min-height: 55px;
  }
}